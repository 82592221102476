<template>
  <div class="how-we-rank-broker-menus">
    <div class="how-we-rank-broker-menus-shape">
      <img src="../../assets/broker-menu/how-we-rank-bg-img.svg" alt="" />
    </div>
    <div class="container-broker-menu">
      <div class="how-we-rank-broker-menus-content">
        <div class="how-we-rank-broker-menus-first-row">
          <div class="how-we-rank-broker-menus-first-row-content">
            <div class="how-we-rank-broker-menus-first-row-content-left">
              <h3>How do we rank our brokers?</h3>
              <p>
                Our panelists continually conduct research into a list of the
                leading brokers. They check some of the key parameters, which
                include regulatory structure, competitiveness of the spreads
                (fixed vs variable), speed of withdrawals, platforms available,
                extra tools, educational resources & market research, and
                finally customer support. For each factor we give a rating out
                of 10 points. We can then add up the points and place the
                brokers in descending order in our list.
              </p>
            </div>
            <div class="how-we-rank-broker-menus-first-row-content-right">
              <img
                src="../../assets/broker-menu/how-we-rank-row-1.svg"
                alt=""
              />
            </div>
          </div>
        </div>

        <div class="how-we-rank-broker-menus-second-row">
          <div class="how-we-rank-broker-menus-first-row-content">
            <div class="how-we-rank-broker-menus-first-row-content-right">
              <img
                src="../../assets/broker-menu/how-we-rank-row-2.svg"
                alt=""
              />
            </div>
            <div
              id="about-broker-menu"
              class="how-we-rank-broker-menus-first-row-content-left"
            >
              <h3>About us</h3>
              <p>
                BROKERS.MENU was established by us, a group of traders with many
                years of experience under our belts. Along the way, we worked
                with a wide range of brokers. We came to understand clearly the
                advantages of one broker over another. <br />

                New traders would come and ask us often to recommend them the
                best broker, because as are you probably already aware, choosing
                the best broker is not an easy job. It entails a lot of
                research, or recommendations from other traders. With that in
                mind, we created BROKERS.MENU with the goal of removing the
                legwork for our readers. Too bad there were no such tools when
                we first started trading all those years ago. But we are happy
                to share this resource with you, and we hope it helps you in
                your trading journey.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style>
.how-we-rank-broker-menus {
  position: relative;
  margin-top: 100px;
}
.how-we-rank-broker-menus-shape {
  position: absolute;
  right: -85px;
  top: 150px;
}
@media (max-width: 1650px) {
  .how-we-rank-broker-menus-shape {
    display: none;
  }
}
.how-we-rank-broker-menus-first-row-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.how-we-rank-broker-menus-first-row-content-left {
  max-width: 674px;
  width: 100%;
  margin: 0 20px;
}
.how-we-rank-broker-menus-first-row-content-left h3 {
  font-size: 38px;
  margin-bottom: 10px;
}
.how-we-rank-broker-menus-first-row-content-left p {
  font-size: 21px;
}

.how-we-rank-broker-menus-first-row-content-right img {
  width: 450px;
}

.how-we-rank-broker-menus-second-row {
  margin-top: 80px;
}

@media (max-width: 1200px) {
  .how-we-rank-broker-menus-first-row-content-left h3 {
    font-size: 30px;
  }

  .how-we-rank-broker-menus-first-row-content-left p {
    font-size: 18px;
  }
  .how-we-rank-broker-menus-first-row-content-right img {
    width: 400px;
  }
}
@media (max-width: 1024px) {
  .how-we-rank-broker-menus-first-row-content-right img {
    width: 330px;
  }

  .how-we-rank-broker-menus-first-row-content-left {
    max-width: 395px;
  }
}
@media (max-width: 800px) {
  .how-we-rank-broker-menus-first-row-content-right img {
    max-width: 300px;
    width: 100%;
  }

  .how-we-rank-broker-menus-first-row-content-left {
    max-width: 300px;
    width: 100%;
  }

  .how-we-rank-broker-menus-first-row-content-left h3 {
    font-size: 26px;
  }

  .how-we-rank-broker-menus-first-row-content-left p {
    font-size: 16px;
  }
  .how-we-rank-broker-menus-second-row {
    margin-top: 50px;
  }
}

@media (max-width: 700px) {
  .how-we-rank-broker-menus-first-row-content {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin: 0 auto;
    text-align: center;
  }

  .how-we-rank-broker-menus-first-row-content-right {
    order: -1;
  }

  .how-we-rank-broker-menus {
    margin-top: 50px;
  }

  .how-we-rank-broker-menus-first-row-content-right img {
    width: 300px;
    margin-bottom: 20px;
  }

  .how-we-rank-broker-menus-first-row-content-left {
    max-width: 500px;
    width: 100%;
  }
}

@media (max-width: 500px) {
  .how-we-rank-broker-menus-first-row-content-right img {
    width: 243px;
    margin-bottom: 20px;
  }
}
</style>